import React, { useState, useEffect } from "react";
const donationLinks = [
"https://ko-fi.com/pcrfrescue"
];

const getDailyLink = () => {
  const startDate = new Date("2025-01-01");
  const today = new Date();
  const daySinceStart = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
  return donationLinks[daySinceStart % donationLinks.length];
};
const Donate = () => {
  const [currentLink, setCurrentLink] = useState(donationLinks[0]);
  useEffect(() => {
    const updateLink = () => {
      const now = new Date();
      const hour = now.getHours();
      if (hour >= 12 && hour < 13) {
        setCurrentLink(getDailyLink());
      } else {
        setCurrentLink(donationLinks[0]); // default link
      }
    };
    updateLink();
    const interval = setInterval(updateLink, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleDonateClick = () => {
    window.location.href = currentLink;
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-50">
      <h1 className="text-3xl font-bold text-blue-600 mb-4">
        Support Our Cause
      </h1>
      <p className="text-lg text-gray-700 mb-6">
        Your generous donation helps us continue making a difference. Thank you
        for your support!
      </p>
      <button
        onClick={handleDonateClick}
        className="px-6 py-3 bg-blue-600 text-white font-bold rounded hover:bg-blue-700 transition"
      >
        Donate Now
      </button>
    </div>
  );
};

export default Donate;
